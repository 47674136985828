import React, {useEffect, useState} from 'react';
import {EmailRounded, Facebook, Instagram, PhoneRounded} from "@mui/icons-material";
import Slide1 from "../img/pic2-cropped2.jpg";
import ImageTitleCard from "./ImageTitleCard";
import Icon1 from "../img/icon/9.png";
import ATV from "../img/icon/ATV2.png";
import Buggy from "../img/icon/Buggy3.png";
import ImageTitleDescriptionCard from "./ImageTitleDescriptionCard";
import Camping from "../img/camping.jpg";
import Traseu1 from "../img/traseu-2.jpg";
import SXS from "../img/sxs.jpg";
import {Box} from "@mui/material";
import Reservations, {MainProps} from "./Reservations";
import Footer from "./Footer";
import ATVImageTitleDescriptionCard from "./ATVImageTitleCard";
import {Language} from "../App";
import ATV1 from "../img/atv-1.jpg";
import ATV2 from "../img/atv-2.jpg";

const Main = ({reservations, setLanguage, language}: MainProps) => {
    return (
        <div className="App">
            <div className="wrapper">
                <header id="header" className="header-area absolute-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <div className="header-top-contact">
                                    <ul>
                                        <li>
                                            <a href="mailto:craciunel_iosif@yahoo.com">
                                                <EmailRounded/>
                                                craciunel_iosif@yahoo.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:0726133032">
                                                <PhoneRounded/>
                                                +40 726 133 032
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=61568747931685"
                                               target="_blank" rel="noopener noreferrer">
                                                <Facebook style={{marginRight: '8px'}}/>
                                                Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/wild_trip_pestenita?igsh=d2pkYmlzdGk0Nzls"
                                               target="_blank" rel="noopener noreferrer">
                                                <Instagram style={{marginRight: '8px'}}/>
                                                Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => setLanguage(Language.romanian)}>
                                                <span style={{color: 'lightgray'}}>RO</span>
                                                <span style={{color: 'white'}}> / EN</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="slider-area">
                    <div id="welcome-slide" className="slide-controls">
                        <div className="single-slide">
                            <div className="slide-thumb shape-1" style={{overflow: 'hidden'}}>
                                <img className={'main-image'} src={Slide1} alt=""/>
                                <div className="slide-content">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 col-sm-12">
                                                <div className="slide-content-area">
                                                    <div className="single-slide-content text-center">
                                                        <div className="content-text">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="info-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* Romanian Government Emblem */}
                            <div className="col-md-2 col-sm-3 text-center">
                                <img src="/romania.png" alt="Romanian Government Emblem" className="banner-icon"/>
                            </div>

                            {/* Central Text */}
                            <div className="col-md-8 col-sm-6 text-center">
                                <h2 className="banner-title">National Rural Development Program</h2>
                                <p className="banner-subtitle">Program funded by the European Union and the Government
                                    of Romania</p>
                                <p className="banner-subtitle">European Agricultural Fund for Rural Development</p>
                                <p className="banner-note">"Europe invests in rural areas"</p>

                                {/* Information from the image */}
                                <div className="project-details">
                                    <h3>Diversification of Crăciunel Ciprian-Iosif Activities</h3>
                                    <p><strong>Beneficiary:</strong> Crăciunel Ciprian-Iosif Individual Enterprise</p>
                                    <p><strong>Total eligible project value:</strong> 50,000 Euro</p>
                                    <p><strong>Public funding through PNDR:</strong> 50,000 Euro</p>
                                    <p><strong>Implementation period:</strong> 06/05/2022 - 06/02/2025</p>
                                </div>
                            </div>

                            {/* EU Logo */}
                            <div className="col-md-2 col-sm-3 text-center">
                                <img src="/europe.png" alt="European Union Logo" className="banner-icon"/>
                            </div>
                        </div>

                        {/* AFIR Link */}
                        <div className="row">
                            <div className="col text-center mt-3">
                                <a
                                    href="https://www.afir.ro/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-link"
                                >
                                    Visit the AFIR website
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="quick-icon-area pb-90">
                    <div className="container">
                        <div className="row">
                            <ImageTitleCard img={Icon1} title={'Camping'}/>
                            <ImageTitleCard img={ATV} title={'ATV'}/>
                            <ImageTitleCard img={Buggy} title={'SXS'}/>
                        </div>
                    </div>
                </div>
                <div className="about-area pb-115">
                    <div className="about-bg">
                        <video
                            className={'video'}
                            controls
                            autoPlay
                            muted
                            loop
                            playsInline
                            style={{
                                borderRadius: 20,
                                height: 'auto',
                                width: '100%'
                            }}>
                            <source src={'videos/about.mp4'} type={'video/mp4'}/>
                        </video>
                    </div>
                    <div className="container" style={{maxWidth: '100%'}}>
                        <div className="row">
                            <div className="col"></div>
                            <div className="col-lg-6">
                                <div className="section-title" style={{marginBottom: 15}}>
                                    <h2 style={{marginBottom: 15}}>Start your adventure<span></span> with <b>Wild
                                        Trip</b>
                                    </h2>
                                </div>
                                <div className="about-content">
                                    <p style={{marginBottom: 10}}>Because we love adventure, we like to discover new
                                        routes and we are addicted to the adrenaline provided by an offroad ride, we
                                        decided to offer this kind of fun to other adventure lovers.</p>
                                    <p style={{marginBottom: 10}}> Our location, located at the foot of the Retezat
                                        mountains, offers spectacular landscapes and trails both for those who want to
                                        get away from everyday life and for those looking for adrenaline. All you have
                                        to do is rent an ATV and go on an Adventure!</p>
                                    <p style={{marginBottom: 10}}>
                                        Our guide will show you how to use the ATV correctly and will introduce you to
                                        the safety rules, and depending on the complexity of the chosen route, he will
                                        accompany you throughout the ride.
                                    </p>
                                    <p style={{marginBottom: 10, fontWeight: 500}}>
                                        Enjoy nature and at the same time relax, have fun, adventure and adrenaline!
                                    </p>
                                    <a href="#reservations" className="btn btn-theme">Book now!</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="adventure-area pb-90 pt-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-md-8">
                                <div className="section-title text-center">
                                    <h2 className="title">What we offer you</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ImageTitleDescriptionCard img={Camping} title={'Camping'} description={
                                <>
                                    <p>We offer the land located near our location for caravan camping. Pitches are
                                        demarcated for up to 5 caravans. Possibility of connection to electricity,
                                        water. Access to toilets, washing machine, internet.</p>
                                    <p> Popular sights in the area: Biserica Sfântul Ierarh Nicolae din Densuș, Tăul
                                        fără fund, Muzeul etnografic Peșteana, Biserica Pogorărea Sfântului Duh
                                        Peșteana, Casa Vulcanilor, Mănăstirea Prislop, Valea Râului Mare, Geoparcul
                                        Dinozaurilor ”Țara Hațegului”, Munții Retezat etc…</p>
                                    <p>The price of renting the parking lot is 100 RON/24h.</p>
                                    <p>We are happy to have as many guests as possible so that they can fully taste the
                                        charm of country life.</p>
                                </>
                            }/>
                            <ImageTitleDescriptionCard img={Traseu1} title={'ATV/SXS trails'} description={
                                <>
                                    <p>The rental of ATVs is done on the basis of a bulletin, category B driver's
                                        license and the signing of a rental contract.</p>
                                    <p>At the end we will make sure together that you have brought our ATV in good
                                        condition so that other adventurers can enjoy it.</p>
                                    <p>ATV CFORCE ride – 150 RON/h</p>
                                    <p>SXS ZFORCE ride – 150 RON/h</p>
                                    <p>The trip will take place on the communal road 90 and on the national road 68.</p>
                                    <p>The route will start from Peștenita village, follow DC 90 towards and through
                                        Peșteana village, until the intersection with DN 68, then continue on DN 68 to
                                        Sarmizegetusa Commune with the terminus at Ulpia Traiana Sarmizegetusa Citadel
                                        and Sarmizegetusa Archeology Museum and return on the same route to the place of
                                        departure.</p>
                                    <p>Route of 9.9 km, which can be covered, round trip, in about 2 hours.</p>
                                </>
                            }/>
                        </div>
                    </div>
                </div>
                <div className="funfact-area overlay-bg pt-170 pb-70">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-md-8">
                                <div className="section-title white text-center">
                                    <h2 className="title">Our ATVs</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ATVImageTitleDescriptionCard
                                img={ATV1}
                                title={'CFORCE625 TOURING - 40CP'}
                            />
                            <ATVImageTitleDescriptionCard
                                img={ATV2}
                                title={'CFORCE 850XC - 59CP'}
                            />

                            <ATVImageTitleDescriptionCard
                                img={SXS}
                                title={'ZFORCE 1000Sport - 78CP'}
                            />
                        </div>
                    </div>
                </div>

                <Box id={'reservations'} className="partner-area pb-220" paddingTop={'50px'}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title text-center">
                                    <h2 className="title">Book now</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">

                            </div>
                        </div>
                    </div>
                    <Reservations reservations={reservations} language={language}/>
                </Box>

                <Footer/>
            </div>
            <script src="js/vendor/jquery-3.3.1.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/owlcarousel.min.js"></script>
            <script src="js/meanmenu.min.js"></script>
            <script src="js/counterup.min.js"></script>
            <script src="js/scrollup.min.js"></script>
            <script src="js/waypoints.min.js"></script>
            <script src="js/imagesloaded.pkgd.min.js"></script>
            <script src="js/isotope.min.js"></script>
            <script src="js/main.js"></script>
        </div>
    );
}

export default Main;