import React, {useEffect, useState} from 'react';
import './css/bootstrap.min.css';
import './css/animate.min.css';
import './css/owl-carousel.min.css';
import './css/meanmenu.min.css';
import './css/cssanimation.min.css';
import './css/default.css';
import './css/style.css';
import './css/responsive.css';
import './css/fontawesome.all.min.css';
import Reservations, {Reservation} from "./components/Reservations";
import {initializeApp} from "firebase/app";
import {collection, getDocs, getFirestore, query, where, onSnapshot, orderBy} from "firebase/firestore";
import {getAuth, signInAnonymously} from "firebase/auth";
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import Admin from "./components/Admin";
import Main from "./components/Main";
import MainEnglish from "./components/MainEnglish";
import Terms from "./components/Terms";

const firebaseConfig = {
    apiKey: "AIzaSyA3CJusryG0MUf3TkLIT13T8hGDmLd1YL4",
    authDomain: "test-wildtrip.firebaseapp.com",
    projectId: "test-wildtrip",
    storageBucket: "test-wildtrip.appspot.com",
    messagingSenderId: "570599001227",
    appId: "1:570599001227:web:2e23b64f23c9bdb97e988e",
    measurementId: "G-203E1E7QQL"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const auth = getAuth();

export enum Language {
    romanian = 'romanian',
    english = 'english'
}

const getLanguage = () => {
    let lng = localStorage.getItem('language');
    if (!lng) {
        localStorage.setItem('language', Language.romanian);
        lng = Language.romanian;
    }

    if (lng === 'english') {
        return Language.english;
    }

    return Language.romanian;
};

const App = () => {
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [language, setLanguage] = useState<Language>(getLanguage());

    useEffect(() => {
        let unsub;
        signInAnonymously(auth).then(async () => {
            const collectionRef = collection(db, 'Reservations');
            unsub = onSnapshot(collectionRef, async () => {
                let q = query(collectionRef);
                // q = query(q, where('startDate', '>', new Date()));
                q = query(q, orderBy('startDate', 'desc'));
                const docs = await getDocs(q);
                setReservations(docs.docs.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.data().name,
                        phone: doc.data().phone,
                        adventureType: doc.data().adventureType,
                        startDate: new Date(doc.data().startDate.seconds * 1000),
                        endDate: doc.data().endDate ? new Date(doc.data().endDate.seconds * 1000) : null,
                        numberOfAtvs: doc.data().numberOfAtvs ?? 1,
                    };
                }));
            })
        });

        return unsub;
    }, []);

    const setNewLanguage = (lng: Language) => {
        setLanguage(lng);
        localStorage.setItem('language', lng);
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/admin'} element={<Admin reservations={reservations} language={language}/>}/>
                <Route path={'/termene-si-conditii'} element={<Terms/>}/>
                <Route path={'*'} element={language === Language.romanian ?
                    <Main reservations={reservations} setLanguage={setNewLanguage} language={language}/> :
                    <MainEnglish reservations={reservations} setLanguage={setNewLanguage} language={language}/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
