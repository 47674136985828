import React, {useLayoutEffect, useState} from 'react';
import {GoogleMap, MarkerF, useJsApiLoader} from '@react-google-maps/api';
import {Email, Facebook, Instagram, LocationOn, Phone} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import Logo from "../img/logo-nobg.png";

// google maps, adresa, contact
const mapOptions: google.maps.MapOptions =
    {
        streetViewControl: false,
        mapTypeControl: false,
        mapTypeId: 'hybrid'
    }

const Footer = () => {
    const center: google.maps.LatLngLiteral = {lat: 45.5525287, lng: 22.8031896};

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBu2cSGoc9p4DOQV8FwNlC7rBjyaqf4Wls",
    });

    return (
        <>
            <footer className="footer-area pt-85 pb-60"
                    style={{display: "flex", justifyContent: 'center', alignContent: 'center'}}>
                <div className={'row container'}>
                    <div className={'footer-container col-lg-6 col-sm-12'}>
                        {isLoaded &&
                            <div className={'google-maps-container'}>
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    center={center}
                                    zoom={15}
                                    options={mapOptions}
                                >
                                    <MarkerF
                                        zIndex={1000}
                                        title={'Wild Trip'}
                                        position={center}
                                        onClick={() => {
                                            window.location.href =
                                                "https://www.google.ro/maps/place/45%C2%B033\'09.1%22N+22%C2%B048\'13.8%22E/@45.5525379,22.8026143,19z/data=!3m1!4b1!4m7!1m2!2m1!1spestenita+nr+43!3m3!8m2!3d45.552537!4d22.803832";
                                        }}
                                    />
                                </GoogleMap>
                            </div>
                        }
                    </div>
                    <div className={'col-lg-6 col-sm-12'} style={{color: 'white', paddingTop: 20, fontSize: 16}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                            flexDirection: 'column'
                        }}>
                            <img src={Logo} style={{height: 120, width: 120}} alt="Logo"/>
                            <p>
                                <LocationOn className={'footer-icon'}/>
                                <a href="https://www.google.ro/maps/place/45%C2%B033'09.1%22N+22%C2%B048'13.8%22E/@45.5525379,22.8026143,19z/data=!3m1!4b1!4m7!1m2!2m1!1spestenita+nr+43!3m3!8m2!3d45.552537!4d22.803832">
                                    Peștenița 43, România
                                </a>
                            </p>
                            <p>
                                <Phone className={'footer-icon'}/>
                                <a href="tel:0726133032">+40 726 133 032</a>
                            </p>
                            <p>
                                <Email className={'footer-icon'}/>
                                <a href="mailto:craciunel_iosif@yahoo.com">craciunel_iosif@yahoo.com</a>
                            </p>
                            <p>
                                <Facebook className={'footer-icon'} style={{color: 'white', marginRight: '8px'}}/>
                                <a href="https://www.facebook.com/profile.php?id=61568747931685" target="_blank"
                                   rel="noopener noreferrer">
                                    Facebook
                                </a>
                            </p>
                            <p>
                                <Instagram className={'footer-icon'} style={{color: 'white', marginRight: '8px'}}/>
                                <a href="https://www.instagram.com/wild_trip_pestenita?igsh=d2pkYmlzdGk0Nzls"
                                   target="_blank" rel="noopener noreferrer">
                                    Instagram
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copy-right-area" style={{backgroundColor: '#21262c'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="copyright-text">
                                <NavLink to={'/admin'}><p>Această activitate a fost realizată cu fonduri europene
                                    A.F.I.R S.M. 6.2</p></NavLink>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="copyright-text">
                                <a href={'https://dotkoda.ro'}><p>© Powered by dotKoda</p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;