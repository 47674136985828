import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, MenuItem, TextField} from "@mui/material";
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import {enGB, ro} from 'date-fns/locale';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from "@mui/x-date-pickers";
import {collection, doc, setDoc} from 'firebase/firestore';
import {db, Language} from "../App";
import {addDays, format} from "date-fns";
import emailjs from '@emailjs/browser';

export type Reservation = {
    id: string,
    adventureType: number;
    name: string;
    phone: string;
    startDate: Date;
    endDate?: Date | null;
    numberOfAtvs?: number | null;
}

export type ReservationsProps = {
    reservations: Reservation[];
    language: Language;
}

export type MainProps = {
    setLanguage: (lng: Language) => void;
    language: Language;
    reservations: Reservation[];
}

const Reservations = ({reservations, language}: ReservationsProps) => {
    const [selectionRange, setSelectionRange] = useState<any>(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    );
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [acceptedTC, setAcceptedTC] = useState<boolean>(false);

    const [adventureType, setAdventureType] = useState<number>(1);
    const [atvTime, setAtvTime] = useState<Date | null>();
    const [numberOfAtvs, setNumberOfAtvs] = useState<number | undefined>(1);

    const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const form = useRef();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        window.onresize = (e) => {
            setWidth(window.innerWidth);
            setIsMobile(window.innerWidth <= 768);
        };
    }, [])

    useEffect(() => {
        let datesMap = new Map<Date, number>();
        reservations.forEach((res: Reservation) => {
            if (res.adventureType === 2) return;

            let startDate = new Date(res.startDate.getFullYear(), res.startDate.getMonth(), res.startDate.getDate());
            let endDate = new Date(res.endDate!.getFullYear(), res.endDate!.getMonth(), res.endDate!.getDate());
            while (startDate <= endDate) {
                datesMap.set(startDate, (datesMap.get(startDate) ?? 0) + 1);
                startDate = addDays(startDate, 1);
            }
        });
        let dates: any[] = [];
        datesMap.forEach((v, k) => {
            if (v >= 5) {
                dates = [...dates, k];
            }
        });

        setUnavailableDates(dates);
    }, [reservations]);

    const reserve = async () => {
        if (isLoading) return;

        setIsLoading(true);
        let isFormValid = name.length > 0 && phone.length === 10 && acceptedTC;
        switch (adventureType) {
            case 1:
                isFormValid = isFormValid && selectionRange.startDate != null && selectionRange.endDate != null;
                if (selectionRange.startDate != null && selectionRange.endDate != null && selectionRange.startDate.getTime() === selectionRange.endDate.getTime()) {
                    if (language === Language.romanian) {
                        alert('Durata minimă a unei rezervări este de 1 zi!');
                    } else {
                        alert('The minimum duration of a reservation is 1 day!');
                    }
                    setIsLoading(false);
                    return;
                }
                break;
            case 2:
                isFormValid = isFormValid && !!atvTime && (!!numberOfAtvs && numberOfAtvs >= 1 && numberOfAtvs <= 4);
                break;
        }

        if (!isFormValid) {
            if (language === Language.romanian) {
                alert('Va rugăm completați toate câmpurile obligatorii din formular și încercați din nou!');
            } else {
                alert('Please fill in all required fields in the form and try again!');
            }
            setIsLoading(false);
            return;
        }

        let data: any = {
            name,
            phone,
            adventureType,
            acceptedTC,
        };
        switch (adventureType) {
            case 1:
                data = {
                    ...data,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                };
                break;
            case 2:
                data = {
                    ...data,
                    startDate: atvTime,
                    numberOfAtvs: numberOfAtvs,
                };
                break;
        }

        const reservationsCollection = collection(db, 'Reservations');
        await setDoc(doc(reservationsCollection), data);
        await emailjs.send(
            'service_964jvge',
            'template_9b1170y',
            {
                'adventureType': data.adventureType === 1 ? 'Camping' : 'Închiriere ATV',
                'name': data.name,
                'phone': data.phone,
                'startDate': data.adventureType === 1 ? format(data.startDate, 'dd/MM/yyyy') : format(data.startDate, 'dd/MM/yyyy - HH:mm'),
                'endDate': data.adventureType === 1 ? format(data.endDate, 'dd/MM/yyyy') : '',
                'numberOfAtvs': data.adventureType === 2 ? data.numberOfAtvs : 0,
                'reply_to': 'craciunel_iosif@yahoo.com'
            },
            'HR7N0YSyUvqFV-L1I'
        );

        if (language === Language.romanian) {
            alert('Rezervare efectuata!');
        } else {
            alert('Reservation made!');
        }
        clearAllFields();
        setIsLoading(false);
    }

    const clearAllFields = () => {
        setName('');
        setPhone('');
        setAtvTime(null);
        setSelectionRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        })
    }

    return (
        <Box display={'flex'} justifyContent={'center'} flexDirection={!isMobile ? 'row' : 'column'} width={'100%'}
             padding={isMobile ? '10px' : '10px 100px'}>
            {
                adventureType === 1 &&
                <Box flex={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <DateRange
                        locale={language === Language.romanian ? ro : enGB}
                        ranges={[selectionRange]}
                        onChange={(range) => setSelectionRange(range.selection)}
                        rangeColors={['#ff5300']}
                        color={'#ff5300'}
                        minDate={new Date()}
                        moveRangeOnFirstSelection={false}
                        disabledDates={unavailableDates}
                        months={2}
                        direction={!isMobile ? 'horizontal' : 'vertical'}
                    />
                </Box>
            }
            <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}
                 maxWidth={isMobile ? '100%' : '50%'}>
                <Box>
                    <Box paddingLeft={!isMobile ? '20px' : 0} paddingRight={!isMobile ? '20px' : 0}>
                        <TextField
                            label={language === Language.romanian ? 'Tipul aventurii' : 'Adventure type'}
                            value={adventureType}
                            onChange={(v) => setAdventureType(parseInt(v.target.value))}
                            select
                            fullWidth
                            required
                        >
                            <MenuItem value={1}>Camping</MenuItem>
                            <MenuItem
                                value={2}>{language === Language.romanian ? 'Închiriere ATV/SXS' : 'ATV/SXS rental'}</MenuItem>
                        </TextField>
                    </Box>
                    {
                        adventureType === 2 &&
                        <Box paddingTop={'20px'} paddingLeft={!isMobile ? '20px' : 0}
                             paddingRight={!isMobile ? '20px' : 0}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ro}>
                                <DateTimePicker
                                    label={language === Language.romanian ? 'Selecteaza data si timpul' : 'Select date and time'}
                                    value={atvTime}
                                    onChange={(value) => setAtvTime(value)}
                                    disablePast
                                    inputFormat={'dd/MM/yyyy HH:mm'}
                                    renderInput={(params) => {
                                        return <TextField {...params} fullWidth required/>
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    }
                    {
                        adventureType === 2 &&
                        <Box paddingTop={'20px'} paddingLeft={!isMobile ? '20px' : 0}
                             paddingRight={!isMobile ? '20px' : 0}>
                            <TextField
                                label={language === Language.romanian ? 'Număr de ATV-uri' : 'ATVs count'}
                                type={'number'}
                                value={numberOfAtvs}
                                onChange={(e) => setNumberOfAtvs(e.target.value.length > 0 ? parseInt(e.target.value) : undefined)}
                                required
                                fullWidth
                            />
                            {
                                (!numberOfAtvs || numberOfAtvs < 1 || numberOfAtvs > 4) &&
                                <p style={{
                                    color: 'red',
                                    fontSize: 14
                                }}>{language === Language.romanian ? '* Alegeți o valoare cuprinsa intre 1 si 4' : '* Pick a value between 1 and 4'}</p>
                            }
                        </Box>
                    }
                    <Box paddingTop={'20px'} paddingLeft={!isMobile ? '20px' : 0} paddingRight={!isMobile ? '20px' : 0}>
                        <TextField
                            label={language === Language.romanian ? 'Nume' : 'Name'}
                            type={'text'}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            fullWidth
                        />
                    </Box>
                    <Box paddingTop={'20px'} paddingLeft={!isMobile ? '20px' : 0} paddingRight={!isMobile ? '20px' : 0}>
                        <TextField
                            label={language === Language.romanian ? 'Număr de telefon' : 'Phone number'}
                            type={'tel'}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            fullWidth
                        />
                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{
                                    color: "#ff5300",
                                }}
                                value={acceptedTC}
                                onChange={(e) => setAcceptedTC(e.target.checked)}
                            />
                        }
                        style={{paddingLeft: isMobile ? 0 : 20, paddingTop: 10}}
                        label={language === Language.romanian ? <span>Sunt de acord cu <a href={'/termene-si-conditii'}
                                                                                          style={{
                                                                                              textDecoration: 'underline',
                                                                                              color: '#ff5300'
                                                                                          }}>termenele si condițiile</a> *</span> :
                            <span>I have read and agreed to the  <a href={'/termene-si-conditii'}
                                                                    style={{
                                                                        textDecoration: 'underline',
                                                                        color: '#ff5300'
                                                                    }}>terms and conditions</a> *</span>}
                    />
                </Box>
                <Box paddingTop={'10px'} paddingLeft={!isMobile ? '20px' : 0} paddingRight={!isMobile ? '20px' : 0} paddingBottom={isMobile ? '50px' : 0}
                     display={'flex'}
                     justifyContent={isMobile ? 'center' : 'flex-end'}>
                    <Button
                        className="btn btn-theme"
                        onClick={reserve}
                    >
                        {language === Language.romanian ? 'Rezervă' : 'Book'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Reservations;